import * as React from 'react';
import {
  Typography,
  Card,
  CardContent,
  Divider,
  CardMedia,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

const StyledCard = styled(Card)({
  padding: '1rem',
  marginBottom: '1rem',
});

export const CreatorPageHeaderCard = ({ creator, isMobile }) => {
  const theme = useTheme();

  if (isMobile) {
    return (
      <StyledCard sx={{ width: "100%" }}>
        <CardContent style={{
          padding: "0rem",
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'center' // Align items to the start of the container
        }}>
          <Typography style={{
            textAlign: "center",
            fontSize: "1.3rem",
            fontWeight: 'bold',
            marginBottom: ".5rem",
          }} variant="h4" gutterBottom>
            {creator.creator_name}
          </Typography>

          {
            creator?.stream_channel_url ?
              <Typography style={{
                textAlign: "left",
                fontSize: ".8rem",
                marginBottom: "1rem",
              }} variant="h6" gutterBottom>
                <a style={{
                  color: "#fff",
                  '&:hover': {
                    color: theme => theme.palette.primary.main,
                    textShadow: '0 0 10px currentColor',
                  }
                }} href={creator.stream_channel_url} target="_blank" rel="noreferrer">Stream Channel</a>
              </Typography>
              :
              null
          }

          <div>
            {creator?.games?.length > 0 ?
              <div style={{
                textAlign: "center",
              }}>
                <Typography variant="h6" style={{ fontSize: "1rem" }}>
                  Games Available
                </Typography>
                <Divider />
                {creator?.games?.map((sessionType) => (
                  <Typography key={sessionType} style={{ color: theme.palette.primary.main, fontSize: "0.8rem" }}>
                    {sessionType}
                  </Typography>
                ))}
              </div>
              : null}

            {creator?.other_session_types?.length > 0 ?
              <div style={{
                textAlign: "center",
              }}>
                <Typography variant="h6" style={{ fontSize: "1rem" }}>
                  Other Available Sessions
                </Typography>
                <Divider />
                {creator?.other_session_types.map((sessionType) => (
                  <Typography key={sessionType} style={{ color: theme.palette.primary.main, fontSize: "0.8rem" }}>
                    {sessionType}
                  </Typography>
                ))}
              </div>
              : null}
          </div>

        </CardContent>
      </StyledCard>
    )
  } else {
    return (
      <StyledCard sx={{ width: "100%" }}>
        <CardContent style={{
          padding: "0rem",
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-start' // Align items to the start of the container
        }}>

          <div style={{
            display: "flex", flexDirection: "column", alignItems: "center"
          }}>
            <Typography style={{
              textAlign: "left",
              fontSize: "2rem",
              fontWeight: 'bold',
              marginBottom: "2rem",
            }} variant="h4" gutterBottom>
              {creator.creator_name}
            </Typography>
            <CardMedia
              component="img"
              alt="Profile Picture"
              image={creator.profile_picture || 'default_profile_pic_url'}
              title="Profile Picture"
              style={{
                maxWidth: '200px',
                height: 'auto',
                borderRadius: '2%',
              }}
            />
            {
              creator?.stream_channel_url ?
                <Typography style={{
                  textAlign: "left",
                  fontSize: "1rem",
                  marginTop: ".5rem",

                }} variant="h6" gutterBottom>
                  <a style={{
                    color: "#fff",
                    '&:hover': {
                      color: theme => theme.palette.primary.main,
                      textShadow: '0 0 10px currentColor',
                    }
                  }} href={creator.stream_channel_url} target="_blank" rel="noreferrer">Stream Channel</a>
                </Typography>
                :
                null
            }
          </div>

          <div>
            {creator?.games?.length > 0 ?
              <div style={{
                textAlign: "right",
              }}>
                <Typography variant="h6" style={{ fontSize: "1.2rem", fontWeight: 'bold', }}>
                  Games Available
                </Typography>
                <Divider />
                {creator?.games?.map((sessionType) => (
                  <Typography key={sessionType} style={{ color: theme.palette.primary.main, fontSize: "1rem" }}>
                    {sessionType}
                  </Typography>
                ))}
              </div>
              : null}

            {creator?.other_session_types?.length > 0 ?
              <div style={{
                textAlign: "right",
              }}>
                <Typography variant="h6" style={{ fontSize: "1.2rem", fontWeight: 'bold', }}>
                  Other Available Sessions
                </Typography>
                <Divider />
                {creator?.other_session_types.map((sessionType) => (
                  <Typography key={sessionType} style={{ color: theme.palette.primary.main, fontSize: "1rem" }}>
                    {sessionType}
                  </Typography>
                ))}
              </div>
              : null}
          </div>

        </CardContent>
      </StyledCard>
    )
  }
};