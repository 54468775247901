import { Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DashboardMenu = ({ setSelectedMenu, selectedMenu, isMobile, isCreator }) => {
  const theme = useTheme();

  // Define a mobile style
  const mobileStyle = {
    fontSize: isMobile ? "1.5rem" : "2rem", // 50% smaller font size on mobile
  };

  return (
    <Grid container direction={"column"} style={{ textAlign: isMobile ? "center" : "", margin: isMobile ? "0" : "0 0 0 2rem" }}>
      <Grid item style={{ marginBottom: isMobile ? '0' : '1rem', flex: 1 }}>
        <Typography
          variant="h6"
          className={`menu-item ${selectedMenu === 'dashboard' ? 'selected' : ''}`}
          onClick={() => setSelectedMenu('dashboard')}
          style={{
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: selectedMenu === 'dashboard' ? 'underline' : 'none',
            ...mobileStyle,
            '&:hover': {
              color: theme.palette.primary.main,
              textShadow: '0 0 10px currentColor',
            }
          }}
        >
          Dashboard
        </Typography>
      </Grid>
      <Grid item style={{ marginBottom: isMobile ? '0' : '1rem', flex: 1 }}>
        <Typography
          variant="h6"
          className={`menu-item ${selectedMenu === 'sessions' ? 'selected' : ''}`}
          onClick={() => setSelectedMenu('sessions')}
          style={{
            cursor: 'pointer',
            color: theme.palette.primary.main,
            textDecoration: selectedMenu === 'sessions' ? 'underline' : 'none',
            ...mobileStyle,
            '&:hover': {
              color: theme.palette.primary.main,
              textShadow: '0 0 10px currentColor',
            }
          }}
        >
          Sessions
        </Typography>
      </Grid>
      {isCreator && (
        <Grid item style={{ flex: 1 }}>
          <Typography
            variant="h6"
            className={`menu-item ${selectedMenu === 'requests' ? 'selected' : ''}`}
            onClick={() => setSelectedMenu('requests')}
            style={{
              cursor: 'pointer',
              color: theme.palette.primary.main,
              textDecoration: selectedMenu === 'requests' ? 'underline' : 'none',
              ...mobileStyle,
              '&:hover': {
                color: theme.palette.primary.main,
                textShadow: '0 0 10px currentColor',
              }
            }}
          >
            Requests
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardMenu;
