import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const StreamerGuide = () => {
  return (
    <Card style={{ margin: "0 0 1rem" }} variant="outlined">
      <CardContent>
        <Typography style={{ textAlign: "center" }} variant="h5" component="h2">
          Streamer Guide:
        </Typography>
        <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}> {/* Adjust padding and style as needed */}
          <li>Create open calendar slots in your dashboard - remember to let your community know!</li>
          <li>You will get notified when someone books a session. You will get the user's gaming  username/ID in the calendar event.</li>
          <li>Once in the game, take a screenshot for proof. You do not need to submit this to PairPlay, but it's good to have in case of any sort of dispute.</li>
          <li>We recommend you use game chat, but you may use Discord if you prefer. If the player is being rude/abusive, mute them. Leave if you must.</li>
          <li>For the session, add the user who booked it beforehand and invite them to game. Remember to remove them after the session. Have fun, and as always reach out to help@pairplay.gg or reference our FAQs if you need anything!</li>
        </ul>
      </CardContent>
    </Card>
  );
};

export default StreamerGuide;