import React from 'react';
import { Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import baseUrl from "../../url";
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  marginTop: '10rem',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  marginTop: '1rem',
  fontSize: '1.2rem',
});


function LoginPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initiateGoogleLogin = () => {
    console.log("Redirecting to Google for authentication.");
    window.location.href = `${baseUrl}/api/google/login`;
  };

  return (
    <StyledContainer>
      <div style={isMobile ? {width: "60%"} : { width: "30%"}}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className=""
          style={{
            padding: "12px 10px", // Standard padding
            margin: "4px 0px 10px",
            color: "#FFFFFF", // Text color
            backgroundColor: "#4285F4", // Google's blue color
            fontFamily: 'Roboto, sans-serif', // Roboto font
            fontWeight: 500, // Medium weight for Roboto
          }}
          onClick={initiateGoogleLogin}
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google Logo"
            style={{
              width: "18px",
              marginRight: "8px",
              backgroundColor: "#FFFFFF", // White tile for Google "G"
            }}
          />
          Sign in with Google
        </Button>

      </div>

      <StyledLink to="/signup" style={{ color: theme.palette.primary.main }}>
        Don't have an account? Sign up
      </StyledLink>
    </StyledContainer>
  );
}

export default LoginPage;
