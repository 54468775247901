import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  Typography,
  Box,
} from '@mui/material';

const roundToNearest15Minutes = (date) => {
  const coeff = 1000 * 60 * 15; // 15 minutes in milliseconds
  return new Date(Math.round(date.getTime() / coeff) * coeff);
};

const mergeConsecutiveSessions = (sessions) => {
  if (sessions.length === 0) return [];

  const sortedSessions = [...sessions].sort((a, b) => new Date(a.start.dateTime) - new Date(b.start.dateTime));
  const mergedSessions = [];
  let currentSession = { ...sortedSessions[0] };

  for (let i = 1; i < sortedSessions.length; i++) {
    const previousEnd = new Date(currentSession.end.dateTime).getTime();
    const currentStart = new Date(sortedSessions[i].start.dateTime).getTime();

    if ((currentStart - previousEnd) <= 60000) {
      // Merge sessions and update the end time to the end time of the later session
      currentSession.end = sortedSessions[i].end;
    } else {
      // Push the current session and start a new one
      mergedSessions.push(currentSession);
      currentSession = { ...sortedSessions[i] };
    }
  }
  mergedSessions.push(currentSession); // Push the last session

  return mergedSessions;
};

export const SessionDetails = ({ selectedEvents, selectedEventRate, isMobile }) => {
  const [mergedSessions, setMergedSessions] = useState([]);

  useEffect(() => {
    setMergedSessions(mergeConsecutiveSessions(selectedEvents));
  }, [selectedEvents]);

  const totalRate = mergedSessions.reduce((sum, event) => {
    const start = roundToNearest15Minutes(new Date(event.start.dateTime));
    const end = roundToNearest15Minutes(new Date(event.end.dateTime));
    const durationHours = (end - start) / (1000 * 60 * 60); // Convert milliseconds to hours
    return sum + durationHours * selectedEventRate;
  }, 0);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: 2, marginBottom: 2, textAlign: "center" }}>
      <Typography style={{ margin: "1rem 0", textShadow: '0 0 10px currentColor', fontSize: isMobile ? "1.3rem" : "" }} align='center' variant="h4">Selected Sessions:</Typography>
      {mergedSessions.map((event, index) => (
        <Box key={index} sx={{ marginBottom: "0.5rem" }}>
          <Typography style={{ fontSize: isMobile ? "1rem" : "1.6rem", marginBottom: ".2rem" }}>{dayjs(event.start.dateTime).format('MMMM D, YYYY')}</Typography>
          <Typography style={{ fontSize: isMobile ? "1rem" : "1.6rem" }}>{dayjs(event.start.dateTime).format('h:mm A')} - {dayjs(event.end.dateTime).format('h:mm A')}</Typography>
        </Box>
      ))}
      <Typography style={{ fontSize: isMobile ? "1.2rem" : "1.7rem", fontWeight: "bold", margin: ".6rem 0 .4rem" }}>${(Math.round(totalRate * 100) / 100).toFixed(2)}</Typography>
    </Box>
  );
};