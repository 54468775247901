// FAQPage.js
import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqs = [
  { question: "Do I need a GMail account?", answer: "Yes. We use gmail for login authentication and for calendar event booking." },
  { question: "How do I add my gaming username/ID?", answer: "You can add your gaming username/ID in your profile settings. Go to your dashboard,  click edit profile, and add your username" },
  { question: "Why does Google say you need access to my calendar?", answer: "We do not actually access your calendar, but rather use the Google calendar API to make PairPlay events for both you and creators. We do not read, create, or delete anything from your calendar outside of events created on PairPlay.gg" },
  { question: "Do you sell my data?", answer: "No. We do not sell our give away any of your personal data ever. We do not share, sell, or inappropriately use any user data, and adhere to all of Google's data and API guidelines." },
  { question: "What happens if a creator doesn't show up to our session?", answer: "You will be entitled to a full refund." },
  { question: "What happens if I don't show up to a session?", answer: "You will not be entitled to a refund." },
  { question: "What happens if I am abusive or mistreat a creator?", answer: "The creator reserves the right to terminate the session early and you will not be entitled to a refund." },
  { question: "What happens if a creator is abusive or mistreats me?", answer: "Please contact us immediately at help@pairplay.gg" },
  { question: "Does PairPlay charge creators a fee?", answer: "PairPlay charges a 10% fee on booked sessions. There are no other fees involved for users booking sessions, and no sign up/subscription fees to be on the platform." },
  { question: "How do I as a creator get paid?", answer: "We use Stripe to process payments. PairPlay charges a 10% fee." },
  { question: "How do I signup as a creator?", answer: "Please contact us at help@pairplay.gg and our team will work to get you on-boarded!" }
];

const FAQPage = () => {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <div style={{ textAlign: "center", margin: "4rem 0 2rem 0", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <Typography style={{ width: "90%" }} variant="h5" component="div" sx={{ color: '#fff' }}>
          PairPlay is a platform for gamers to connect with their favorite creatprs and play games together. We leverage Google's Calendar and Authentication APIs to make it easy for creators to schedule and manage gaming sessions with their fans.  When you book a session, we will automatically create an event with the details in your Google Calendar.
        </Typography>
      </div>
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>
      <Typography style={{ margin: "1rem 0" }} variant="subtitle1" gutterBottom>
        Have another question? Contact us on <a style={{ color: "white" }} href="https://x.com/pairplaygg">X</a> or by <a style={{ color: "white" }} href="mailto:help@pairplay.gg">email</a>.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {faqs.map((faq, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQPage;
