import React, { useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
import Loading from '../Loading';

const BankInfo = ({ userId, isMobile, onBankInfoAdded }) => {
  const [loading, setLoading] = useState(false);

  const redirectToStripe = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/api/stripe/create-account-link/${userId}`);
      if (response.status === 200) {
        window.location.href = response.data.url; // Redirect to Stripe
      }
      setLoading(false);
    } catch (error) {
      console.error('Error redirecting to Stripe:', error);
      toast.error('Failed to redirect to Stripe. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "1rem", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      {loading ? <Loading /> : null}
      <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
      <Tooltip title="Please add your banking information so that PairPlay can pay you!" arrow>
        <Button
          style={isMobile ? { width: "80%" } : { width: "30%", fontSize: "1.2rem", padding: "15px 30px" }}
          variant="contained"
          color="secondary"
          onClick={redirectToStripe}
          disabled={loading}
        >
          Add Bank Info
        </Button>
      </Tooltip>
    </div>
  );
};

export default BankInfo;
