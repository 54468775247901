import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';

//MUI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import { CreatorModal } from './BecomeCreatorModal';

import '../../styles.css';

const drawerWidth = 240;

export default function Navbar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authUser, setAuthUser } = React.useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [creatorModalOpen, setCreatorModalOpen] = useState(false);

  const toggleCreatorModal = () => {
    if (!authUser) {
      toast.error('You must be logged in to become a creator');
      return;
    }
    setCreatorModalOpen(!creatorModalOpen);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("userId");
    setAuthUser(null);
    navigate("/");
  };


  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: '1rem' }}>
        <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
          <Typography variant="h6" sx={{ my: 2, marginLeft: "1rem" }} className='customHoverEffect'>
            PairPlay.gg
          </Typography>

        </Link>
        <CloseIcon onClick={(e) => { e.stopPropagation(); handleDrawerToggle(); }} style={{ cursor: 'pointer' }} />
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={toggleCreatorModal}>
            <ListItemText style={{
              color: theme.palette.primary.main,
              textShadow: '0 0 10px currentColor',
            }} primary="Become a Creator" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} >
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
              <ListItemText primary="Home" />
            </Link>
          </ListItemButton>
        </ListItem>
        {!authUser ?
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <Link to="/login" style={{ color: "inherit", textDecoration: "none" }}>
                <ListItemText primary="Login" />
              </Link>
            </ListItemButton>
          </ListItem>
          :
          <>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <Link to="/dashboard" style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItemText primary="Dashboard" />
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogout} >
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </>
        }

      </List>
    </Box>
  );

  const container = typeof window !== 'undefined' ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex', marginBottom: "100px" }}>
      <CssBaseline />

      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: 'none', sm: 'block' },
              textAlign: 'left',
              textDecoration: "none",
              color: "#fff",
              '&:hover': {
                color: theme => theme.palette.primary.main,
                textShadow: '0 0 10px currentColor',
              }
            }}
          >
            <Link to="/" style={{
              textTransform: "none", fontSize: "1.5rem", textDecoration: "none", color: "#fff",
            }}>
              PairPlay.gg
            </Link>
          </Typography>

          {/* This Box will center the "Become a Creator" link */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <Typography
              sx={{
                '&:hover': {
                  textShadow: '0 0 10px currentColor',
                },
                display: { xs: 'none', sm: 'flex' }
              }}
              onClick={toggleCreatorModal}
              style={{
                marginLeft: "9.2rem",
                cursor: 'pointer',
                textTransform: "none",
                fontSize: "1.5rem",
                color: theme.palette.primary.main,
              }}
            >
              Become a Creator
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Typography sx={{
              display: { xs: 'none', sm: 'flex' },
              color: '#fff',
              margin: "0 .4rem",
              '&:hover': {
                color: theme => theme.palette.primary.main,
                textShadow: '0 0 10px currentColor',
              }
            }}>
              <Link to="/" style={{ textTransform: "none", fontSize: "1.5rem", color: "inherit", textDecoration: "none" }}>
                Home
              </Link>
            </Typography>

            {authUser && (
              <Typography sx={{
                display: { xs: 'none', sm: 'flex' },
                color: '#fff',
                margin: "0 .4rem",
                '&:hover': {
                  color: theme => theme.palette.primary.main,
                  textShadow: '0 0 10px currentColor',
                }
              }}>
                <Link to="/dashboard" style={{ textTransform: "none", fontSize: "1.5rem", color: "inherit", textDecoration: "none" }}>
                  Dashboard
                </Link>
              </Typography>
            )}

            <Typography
              sx={{
                display: { xs: 'none', sm: 'flex' },
                color: '#fff',
                margin: "0 .4rem",
                '&:hover': {
                  color: theme => theme.palette.primary.main,
                  textShadow: '0 0 10px currentColor',
                }
              }}
              onClick={!authUser ? undefined : handleLogout}
            >
              {!authUser ?
                <Link to="/login" style={{ textTransform: "none", fontSize: "1.5rem", color: "inherit", textDecoration: "none", margin: "0 1rem 0 0" }}>
                  Login
                </Link>
                :
                <span style={{ textTransform: "none", fontSize: "1.5rem" }}>Logout</span>
              }
            </Typography>

          </Box>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
      <CreatorModal user={authUser} isOpen={creatorModalOpen} onClose={() => setCreatorModalOpen(false)} />
    </Box>
  );
}
