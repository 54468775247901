import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Box, FormControl, InputLabel, Select, MenuItem, Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../Loading';

const generateTimeSlots = () => {
  const slots = [];
  const startOfDay = moment().startOf('day');
  for (let i = 0; i < 24 * 4; i++) {
    slots.push(startOfDay.clone().add(i * 15, 'minutes').format('hh:mm A'));
  }
  return slots;
};

const roundToNext15Min = (date) => {
  const remainder = 15 - date.minute() % 15;
  return date.clone().add(remainder, 'minutes').seconds(0);
};

const GoogleEventCreator = ({ creatorId, onCancel, isMobile, setLoading, loading }) => {
  const timeSlots = generateTimeSlots();
  const [selectedDate, setSelectedDate] = useState(moment());
  const [startTime, setStartTime] = useState(roundToNext15Min(moment()).format('hh:mm A'));
  const [endTime, setEndTime] = useState(roundToNext15Min(moment()).add(1, 'hours').format('hh:mm A'));
  const [repeat, setRepeat] = useState('none');
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const handleRepeatChange = (event) => {
    setRepeat(event.target.value);
  };

  const handleCreateEvent = async () => {
    const eventStartDateTime = selectedDate.clone().hour(moment(startTime, 'hh:mm A').hour()).minute(moment(startTime, 'hh:mm A').minute());
    const eventEndDateTime = selectedDate.clone().hour(moment(endTime, 'hh:mm A').hour()).minute(moment(endTime, 'hh:mm A').minute());

    if (eventStartDateTime.isSameOrAfter(eventEndDateTime)) {
      toast.error("Please ensure the end time is after the start time.");
      return;
    }

    const duration = moment.duration(eventEndDateTime.diff(eventStartDateTime));
    const totalHours = duration.asHours();

    // Error handling for minimum session time and even hours requirement
    if (totalHours < 1) {
      toast.error("The minimum session duration is 1 hour.");
      return;
    }

    if (totalHours % 1 !== 0) {
      toast.error("Session duration must be an even number of hours.");
      return;
    }

    const events = [];
    for (let i = 0; i < totalHours; i++) {
      const start = eventStartDateTime.clone().add(i, 'hours');
      const end = start.clone().add(1, 'hour');
      events.push({
        summary: 'pairplay open',
        start: {
          dateTime: start.toISOString(),
        },
        end: {
          dateTime: end.toISOString(),
        },
        recurrence: [],
        timeZone: userTimeZone,
      });
    }

    try {
      setLoading(true);
      await axios.post(`/api/users/creator-calendar/${creatorId}`, { events });
      onCancel();
      toast.success('Event created successfully!');
    } catch (error) {
      console.error('Error creating event:', error);
      toast.error('Failed to create event. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />

  const formContent = (
    <>
      <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
        Note: Sessions longer than 1 hour will automatically be split into consecutive 1-hour blocks.
      </Typography>
      <FormControl fullWidth margin="normal">
        <DatePicker
          label="Select Date"
          value={selectedDate}
          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel variant='outlined' id="start-time-label">Start Time</InputLabel>
        <Select
          labelId="start-time-label"
          value={startTime}
          onChange={handleStartTimeChange}
          renderValue={(value) => value}
          label="Start Time"
        >
          {timeSlots.map((time, index) => (
            <MenuItem key={index} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="end-time-label">End Time</InputLabel>
        <Select
          labelId="end-time-label"
          value={endTime}
          onChange={handleEndTimeChange}
          renderValue={(value) => value}
          label="End Time"
        >
          {timeSlots.map((time, index) => (
            <MenuItem key={index} value={time}>
              {time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <InputLabel id="repeat-label">Repeat</InputLabel>
        <Select
          labelId="repeat-label"
          value={repeat}
          onChange={handleRepeatChange}
          label="Repeat"
        >
          <MenuItem value="none">Does not repeat</MenuItem>
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleCreateEvent} sx={{ mt: 2, mr: 1 }}>
        Create Open Session
      </Button>
      <Button variant="outlined" color="secondary" onClick={onCancel} sx={{ mt: 2 }}>
        Cancel
      </Button>
    </>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ width: isMobile ? '100%' : '40%', textAlign: 'center', padding: 2 }}>
        <Typography variant="h6" style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold" }}>
          Create Open Session
        </Typography>
        {formContent}
      </Box>
      <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
    </LocalizationProvider>
  );
};

export default GoogleEventCreator;