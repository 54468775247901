import React, { useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

import { styled } from '@mui/system';
import { Button, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Link } from 'react-router-dom';
import baseUrl from "../../url";
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  marginTop: '10rem',
});

const StyledLink = styled(Link)({
  textDecoration: 'none',
  marginTop: '1rem',
});

const SignupPage = () => {
  const { setAuthUser } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.onload = () => {
        initGoogleSignup();
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    } else {
      initGoogleSignup();
    }

    function initGoogleSignup() {
      window.google.accounts.id.initialize({
        client_id: "1004189660145-iv3mtqbckqglbr3ogc2qln2p8eblqetu.apps.googleusercontent.com",
        callback: handleGoogleSignup,
        auto_prompt: false,
      });

      const signUpButton = document.getElementById("google-signup-button");
      signUpButton.addEventListener("click", (e) => {
        e.preventDefault();
        window.google.accounts.id.prompt();
      });

      return () => {
        signUpButton.removeEventListener("click", (e) => {
          e.preventDefault();
          window.google.accounts.id.prompt();
        });
      };
    }
    // eslint-disable-next-line 
  }, []);

  const initiateGoogleLogin = () => {
    window.location.href = `${baseUrl}/api/google/login`;
  };

  const handleGoogleSignup = async (credential) => {
    try {
      const res = await axios.post(`${baseUrl}/api/google/login`, {
        id_token: credential.credential,
      });

      // Storing the received user details in the user context.
      const user = res.data.user;
      setAuthUser(user);

      // Optionally, to maintain a session upon page reloads:
      localStorage.setItem("jwt", res.data.token);
      localStorage.setItem("userId", user.id);

      navigate("/");
    } catch (err) {
      console.log("Google signup failed on the server:", err);
    }
  };

  return (
    <StyledContainer>
      <div style={isMobile ? {width: "60%"} : { width: "30%"}} id="google-signup-button">
      <Button
          fullWidth
          variant="contained"
          color="primary"
          className=""
          style={{
            padding: "12px 10px", // Standard padding
            margin: "4px 0px 10px",
            color: "#FFFFFF", // Text color
            backgroundColor: "#4285F4", // Google's blue color
            fontFamily: 'Roboto, sans-serif', // Roboto font
            fontWeight: 500, // Medium weight for Roboto
          }}
          onClick={initiateGoogleLogin}
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google Logo"
            style={{
              width: "18px",
              marginRight: "8px",
              backgroundColor: "#FFFFFF", // White tile for Google "G"
            }}
          />
          Sign up with Google
        </Button>
      </div>

      <StyledLink
        component={NavLink}
        to="/login"
        style={{
          margin: "0 auto",
          padding: "10px 0 6px",
          fontSize: "1.2rem",
          textAlign: "center",
          color: theme.palette.primary.main
        }}
      >
        Already have an account? Click here to log in.
      </StyledLink>
      <Typography style={{ marginTop: "24px", textAlign: "center" }} gutterBottom>
        PairPlay requires a Google account to sign up.
      </Typography>
    </StyledContainer>
  );
};

export default SignupPage;
