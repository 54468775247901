import * as React from 'react';
import dayjs from 'dayjs';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { useTheme } from '@mui/material/styles';
import '../../styles.css';

export const Calendar = ({ handleDayClick, availableSessions, isMobile }) => {
  const theme = useTheme();

  const renderDayCellContent = ({ date, isToday }) => {
    // Determine if there are any available sessions on this day
    const hasAvailableSessions = availableSessions.some(session =>
      dayjs(session.start.dateTime).isSame(dayjs(date), 'day')
    );

    // Define styles for the container
    const containerStyles = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',  // Aligns items to the start (left) of the container
      justifyContent: 'center',  // Centers items along the column
      height: '100%',
      padding: theme.spacing(1)  // Adds some padding
    };

    // Define styles for the day number
    const dayNumberStyles = {
      alignSelf: 'flex-end',  // Aligns the day number to the end (right) of the container
      marginBottom: "14px",   // Pushes the day number to the top
      width: "100%",
      paddingBottom: isMobile ? "0" : "14px",
      fontSize: isMobile ? "1rem" : "1.5rem",
    };

    // Define styles for the "VIEW sessions" text
    const viewSessionsStyles = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      color: theme.palette.primary.main,
      width: "100%",
      textShadow: '0 0 10px currentColor',
      fontSize: isMobile ? ".8rem" : "1.3rem",
    };

    return (
      <div style={containerStyles}>
        <div style={dayNumberStyles}>
          {isToday ? <strong>{date.getDate()}</strong> : date.getDate()}
        </div>
        {hasAvailableSessions && (
          <div style={viewSessionsStyles}>
            {isMobile ? "VIEW" : "VIEW SESSIONS"}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={isMobile ? 'mobile-calendar' : 'desktop-calendar'}>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: isMobile ? "" : ""
        }}
        editable={true}
        height={isMobile ? "550px" : "auto"}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        // className={isMobile ? 'mobile-calendar' : 'desktop-calendar'}
        weekends={true}
        dayCellClassNames={() => isMobile ? ["day-cell-mobile"] : ["day-hover"]}
        dayCellContent={renderDayCellContent}
        dateClick={handleDayClick}
      />
    </div>
  );
}