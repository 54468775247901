import React, { useState } from 'react';
import {
  TextField, Button, Box, FormControl, InputLabel, Select, MenuItem, Typography, Dialog, DialogContent
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import baseUrl from '../../url';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

const generateTimeSlots = () => {
  const slots = [];
  const startOfDay = dayjs().startOf('day');
  for (let i = 0; i < 24 * 4; i++) {
    slots.push(startOfDay.add(i * 15, 'minute').format('hh:mm A'));
  }
  return slots;
};

const roundToNext15Min = (date) => {
  const remainder = 15 - (date.minute() % 15);
  return date.add(remainder, 'minute').second(0);
};

const RequestTimeModal = ({ open, onClose, creator, availableSessions, user }) => {
  const timeSlots = generateTimeSlots();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(roundToNext15Min(dayjs()).format('hh:mm A'));
  const [endTime, setEndTime] = useState(roundToNext15Min(dayjs()).add(1, 'hour').format('hh:mm A'));
  const [error, setError] = useState('');
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleStartTimeChange = (event) => {
    const selectedStartTime = event.target.value;
    setStartTime(selectedStartTime);

    const startMoment = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${selectedStartTime}`, 'YYYY-MM-DD hh:mm A');
    const endMoment = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD hh:mm A');

    if (startMoment.isSameOrAfter(endMoment)) {
      const newEndTime = startMoment.add(1, 'hour').format('hh:mm A');
      setEndTime(newEndTime);
    }
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const validateTimeRange = () => {
    const startDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD hh:mm A');
    const endDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD hh:mm A');

    if (!startDateTime.isValid() || !endDateTime.isValid()) {
      setError('Invalid start or end time.');
      return false;
    }

    if (startDateTime.isSameOrAfter(endDateTime)) {
      setError('End time must be after start time.');
      return false;
    }

    const durationHours = endDateTime.diff(startDateTime, 'hour', true);
    if (durationHours % 1 !== 0 || durationHours < 1) {
      setError('Session duration must be a whole number of hours (minimum 1 hour).');
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmit = async () => {
    if (!validateTimeRange()) return;
  
    try {
      // Refetch pending requests directly, and don't rely on state
      const response = await axios.get(`${baseUrl}/api/session-requests/user/${user.id}/pending-requests`);
      const currentPendingRequests = response.data;
  
      // Check again if there are more than or equal to 3 pending requests
      if (currentPendingRequests.length >= 3) {
        toast.error('You have reached the maximum number of pending requests.');
        return;
      }
  
      const startDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${startTime}`, 'YYYY-MM-DD hh:mm A').tz(userTimeZone);
      const endDateTime = dayjs(`${selectedDate.format('YYYY-MM-DD')} ${endTime}`, 'YYYY-MM-DD hh:mm A').tz(userTimeZone);
  
      const startTimeInUserTz = startDateTime.format();
      const endTimeInUserTz = endDateTime.format();
  
      // Check if the requested time conflicts with any available sessions
      const isTimeAvailable = availableSessions.some(session =>
        startDateTime.isBetween(dayjs(session.start.dateTime), dayjs(session.end.dateTime), null, '[)') ||
        endDateTime.isBetween(dayjs(session.start.dateTime), dayjs(session.end.dateTime), null, '(]') ||
        (startDateTime.isSame(dayjs(session.start.dateTime)) && endDateTime.isSame(dayjs(session.end.dateTime)))
      );
  
      if (isTimeAvailable) {
        toast.error('This time slot conflicts with an available session. Please choose a different time.');
        return;
      }
  
      // Proceed with submitting the request
      await axios.post(`${baseUrl}/api/session-requests/creator/${creator.id}/session-request`, {
        userId: user.id,
        startDateTime: startTimeInUserTz,
        endDateTime: endTimeInUserTz,
        userTimeZone,
      });
  
      toast.success('Time request submitted successfully!');
      onClose();
    } catch (error) {
      console.error('Error submitting time request:', error);
      toast.error('Failed to submit time request. Please try again.');
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ width: '100%', maxWidth: 300, margin: '0 auto', textAlign: 'center', padding: 2 }}>
            <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold', mb: 1 }}>
              Request a Time with {creator.creator_name}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center" sx={{ mb: 2 }}>
              Note: Session duration must be a whole number of hours (minimum 1 hour).
            </Typography>
            <FormControl fullWidth margin="normal">
              <DatePicker
                label="Select Date"
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel variant='outlined' id="start-time-label">Start Time</InputLabel>
              <Select
                labelId="start-time-label"
                value={startTime}
                onChange={handleStartTimeChange}
                renderValue={(value) => value}
                label="Start Time"
              >
                {timeSlots.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel variant='outlined' id="end-time-label">End Time</InputLabel>
              <Select
                labelId="end-time-label"
                value={endTime}
                onChange={handleEndTimeChange}
                renderValue={(value) => value}
                label="End Time"
              >
                {timeSlots.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {error && <Typography color="error">{error}</Typography>}
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2, mr: 1 }}>
              Submit Request
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose} sx={{ mt: 2 }}>
              Cancel
            </Button>
            <ToastContainer toastStyle={{ backgroundColor: '#333', color: '#fff' }} />
          </Box>
        </LocalizationProvider>
      </DialogContent>
    </Dialog>
  );
};

export default RequestTimeModal;
