import { Button, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/system';

const StyledContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const HeaderTypography = styled(Typography)({
  marginBottom: 20,
  fontWeight: 'bold',
});

const SubHeaderTypography = styled(Typography)({
  marginBottom: 30,
});

const FourZeroFour = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  }

  return (
    <StyledContainer>
      <HeaderTypography variant="h1" color="textPrimary">
        404
      </HeaderTypography>
      <SubHeaderTypography variant="h5" color="textSecondary">
        Page not found.
      </SubHeaderTypography>
      <Button variant="contained" color="primary" onClick={handleHomeClick}>
        Go Home
      </Button>
    </StyledContainer>
  );
}

export default FourZeroFour;