import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import '../../styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CalendarButtons = ({ user, creator, selectedEvents, handleBookingError, handleUnselectSessionClick, isMobile, smoothScrollTo, toggleModal, handleDeleteSessionClick }) => {
  const bookSessionButtonRef = useRef(null);

  const scrollToBookSessionButton = () => {
    setTimeout(() => {
      if (bookSessionButtonRef.current) {
        smoothScrollTo(bookSessionButtonRef.current);
      }
    }, 500);
  };

  const handleBookSessionClick = () => {
    if (user) {
      toggleModal();
    } else {
      handleBookingError();
    }
  };

  useEffect(() => {
    if (selectedEvents.length > 0 && selectedEvents.length < 2) {
      scrollToBookSessionButton(bookSessionButtonRef);
    }
  }, [selectedEvents]);

  const canDeleteSession = user && (user.id === creator.id || user.manager_creator_id === creator.id);

  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
      <Button
        variant="contained"
        color="primary"
        disabled={selectedEvents.length === 0 || !user}
        onClick={handleBookSessionClick}
        style={{ width: isMobile ? "80%" : "35%", padding: isMobile ? ".5rem 0" : "1rem 0", alignSelf: "center", fontSize: isMobile ? "1rem" : "1.2rem", marginBottom: "1rem" }}
        ref={bookSessionButtonRef}
      >
        {user ? "Book Session" : "Log in to book session"}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        disabled={selectedEvents.length === 0}
        onClick={handleUnselectSessionClick}
        style={{ width: isMobile ? "80%" : "35%", padding: isMobile ? ".5rem 0" : "1rem 0", alignSelf: "center", fontSize: isMobile ? "1rem" : "1.2rem" }}
      >
        Unselect Session
      </Button>
      {canDeleteSession && (
        <Button
          variant="contained"
          color="error"
          disabled={selectedEvents.length === 0}
          onClick={handleDeleteSessionClick}
          style={{ width: isMobile ? "80%" : "35%", padding: isMobile ? ".5rem 0" : "1rem 0", alignSelf: "center", fontSize: isMobile ? "1rem" : "1.2rem", marginTop: "1rem" }}
        >
          Delete Session
        </Button>
      )}
      <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
    </div>
  );
};