// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from './url';  // Adjust the import path to your baseURL file

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      axios.get(`${baseURL}/api/users/${userId}`)
        .then(response => {
          setAuthUser(response.data);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [userId]);

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};
