import React, { useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useLocation } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../url";
import "../../styles.css";
import Loading from "../Loading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD);
export const CheckoutForm = ({ amount, onSuccess }) => {
  const location = useLocation();
  const { creator, selectedEvents, user, userNotes, sessionType, game } = location.state;

  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const createCheckoutSession = async () => {
      try {
        const response = await axios.post(`${baseUrl}/api/stripe/create-checkout-session`, {
          creatorId: creator.id,
          selectedEvents,
          notes: userNotes,
          userId: user.id,
          sessionType,
          game
        });
        setClientSecret(response.data.clientSecret);
        setLoading(false);
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    };
    createCheckoutSession();
    // eslint-disable-next-line 
  }, []);

  if (loading) return <Loading />

  return (
    <div id="checkout" style={{ backgroundColor: "white"}}>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
          
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  ); 
};
