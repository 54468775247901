import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
} from '@mui/material';
import baseURL from '../../url';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Requests = ({ user }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.get(`${baseURL}/api/session-requests/creator/${user.id}/pending-requests`);
        console.log("response", response.data);
        setRequests(response.data);
      } catch (error) {
        console.error('Error fetching requests:', error);
      }
      setLoading(false);
    };

    fetchRequests();
  }, [user.id]);

  const handleAccept = async (requestId) => {
    try {
      await axios.post(`${baseURL}/api/session-requests/accept/${requestId}`);
      // Remove the accepted request from the list
      setRequests(requests.filter(request => request.id !== requestId));
      toast.success('Session request accepted successfully!');
    } catch (error) {
      console.error('Error accepting session request:', error);
      toast.error('Failed to accept session request. Please try again.');
    }
  };

  const handleDeny = async (requestId) => {
    try {
      await axios.post(`${baseURL}/api/session-requests/decline/${requestId}`);
      // Remove the denied request from the list
      setRequests(requests.filter(request => request.id !== requestId));
      toast.success('Session request denied.');
    } catch (error) {
      console.error('Error denying session request:', error);
      toast.error('Failed to deny session request. Please try again.');
    }
  };

  if (loading) {
    return <Typography>Loading requests...</Typography>;
  }

  if (requests.length === 0) {
    return <Typography>No Current requests</Typography>;
  }

  return (
    <>
      <Grid container spacing={2}>
        {requests.map(request => (
          <Grid item xs={12} md={6} key={request.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">Session Request</Typography>
                <Typography variant="body2">User Name: {request.user.name}</Typography>
                <Typography variant="body2">User Email: {request.user.email}</Typography>
                <Typography variant="body2">
                  Start time: {new Date(request.start_time).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true })}
                </Typography>
                <Typography variant="body2">
                  End time: {new Date(request.end_time).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: '2-digit', hour12: true })}
                </Typography>
                <div style={{ marginTop: '1rem' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAccept(request.id)}
                    style={{ marginRight: '0.5rem' }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeny(request.id)}
                  >
                    Deny
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
    </>
  );
};

export default Requests;
