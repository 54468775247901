import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseURL from '../../url';
import { Typography, Card, CardContent, Tabs, Tab, Box } from '@mui/material';
import Loading from '../Loading';

const Sessions = ({ isMobile, user }) => {
  const [sessions, setSessions] = useState({ upcoming: [], previous: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0); // 0 for upcoming, 1 for previous

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseURL}/api/events/users/${user.id}`);
        const now = new Date();
        const allSessions = response.data.map((session) => ({
          ...session,
          start: JSON.parse(session.start),
          end: JSON.parse(session.end),
        }));

        const sortedSessions = allSessions.reduce((acc, session) => {
          const sessionEndTime = new Date(session.end.dateTime);
          if (sessionEndTime > now) {
            acc.upcoming.push(session);
          } else {
            acc.previous.push(session);
          }
          return acc;
        }, { upcoming: [], previous: [] });

        setSessions(sortedSessions);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id) {
      fetchSessions();
    }
  }, [user, user.id]);

  if (loading || !user) {
    return <Loading />;
  }

  if (error) {
    return <Typography color="error">An error occurred: {error.message}</Typography>;
  }

  const getUserNameByGame = (session) => {
    if (session.game === "Call of Duty") {
      return session.user_activision_id;
    }

    if (session.game === "Fortnite") {
      return session.user_epic_username;
    }

    if (session.game === "Dead by Daylight") {
      return session.user_dead_by_daylight_id;
    }

    if (session.game === "Fall Guys") {
      return session.user_epic_username;
    }

    if (session.game === "Minecraft") {
      return session.user_gamertag;
    }

    if (session.game === "Apex Legends") {
      return session.user_ea_id;
    }

    if (session.game === "Valorant") {
      return session.user_riot_id;
    }

    if (session.game === "GTA V") {
      return session.user_rockstar_social_club_account;
    }

    return "N/A";
  }

  const renderSessionCards = (sessionList) => {
    const sortedSessionList = sessionList.sort((a, b) =>
      new Date(a.start.dateTime) - new Date(b.start.dateTime)
    );
    return sortedSessionList.length ? (
      sessionList.map((session) => (
        <Card style={{ maxWidth: "600px" }} key={session.id} variant="outlined">
          <CardContent>
            <Typography style={{ fontSize: isMobile ? ".8rem" : "1.5rem" }}>{`Start: ${new Date(session.start.dateTime).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}`}</Typography>
            <Typography style={{ fontSize: isMobile ? ".8rem" : "1.5rem" }}>{`End: ${new Date(session.end.dateTime).toLocaleString([], {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}`}</Typography>
            <Typography style={{ fontSize: isMobile ? ".8rem" : "1.5rem" }}>{`${session.session_type ? `Session Game` : `Session Type`}: ${session.game || "Unknown"}`}</Typography>
            <Typography style={{ fontSize: isMobile ? ".8rem" : "1.5rem" }}>{user?.is_creator ? `Username/ID: ${getUserNameByGame(session)}` : `Creator: ${session.creator_name}`}</Typography>
            <Typography style={{ fontSize: isMobile ? ".8rem" : "1.5rem" }}>{`Cost: $${session.session_cost_cents / 100}`}</Typography>
            {
              session.session_type ? 
              <Typography style={{ fontSize: isMobile ? ".8rem" : "1.5rem" }}>{`Session Type: ${session.session_type || "Other"}`}</Typography>
              : null
            }
            {session.notes ?
              <Typography style={{ fontSize: isMobile ? ".8rem" : "1.5rem" }}>{`Notes: ${session.notes || ""}`}</Typography>
              : null}
          </CardContent>
        </Card>
      ))
    ) : (
      <Typography style={{ fontSize: isMobile ? "1rem" : "1.5rem" }}>No sessions found.</Typography>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs style={{ justifyContent: isMobile ? "center" : "" }} value={tabValue} onChange={handleChangeTab} aria-label="session tabs">
          <Tab style={{ textTransform: "none", fontSize: isMobile ? ".82rem" : "1.3rem" }} label="Upcoming Sessions" />
          <Tab style={{ textTransform: "none", fontSize: isMobile ? ".82rem" : "1.3rem" }} label="Previous Sessions" />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        {renderSessionCards(sessions.upcoming)}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        {renderSessionCards(sessions.previous)}
      </TabPanel>
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`session-tabpanel-${index}`}
      aria-labelledby={`session-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default Sessions;
