import React, { useState, useEffect, useCallback } from "react";
import { Navigate, Link } from "react-router-dom";
import axios from 'axios';
import baseURL from '../../url';

import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Loading from "../Loading";

export const Return = () => {
  const [status, setStatus] = useState(null);
  const [creatorId, setCreatorId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [eventIds, setEventIds] = useState(null);
  const [isBooking, setIsBooking] = useState(false);
  const [bookingSuccessful, setBookingSuccessful] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`${baseURL}/api/stripe/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCreatorId(data.metadata.creatorId);
        setUserId(data.metadata.userId);
        setEventIds(data.metadata.eventIds);
      })
      .catch(error => console.error('Error fetching session status:', error));
  }, []);

  const handleBookSession = useCallback(async () => {
    setIsBooking(true);

    try {
      const response = await axios.post(`${baseURL}/api/users/schedule-appointment/${creatorId}`, {
        userId,
        eventIds,
      });
      if (response.status === 200) {
        setBookingSuccessful(true);
      } else {
        console.error('Error booking session:', response.data);
      }
    } catch (error) {
      console.error('Error booking session:', error);
    }
    setIsBooking(false);
  }, [creatorId, userId]);

  useEffect(() => {
    if (status === 'complete') {
      handleBookSession();
    }
  }, [status, handleBookSession]);

  if (isBooking) return <Loading />;

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  const buttonStyles = isMobile
    ? { margin: "1rem 0 0 0", width: '80%', fontSize: '1rem', padding: '10px 20px' }
    : { margin: "1rem 0 0 0", fontSize: '1.2rem', padding: '15px 30px' };

  if (bookingSuccessful) {
    return (
      <section style={{ minHeight: '90vh' }} id="success">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2 style={{
            textAlign: "center",
            fontSize: isMobile ? "2rem" : "4rem", // smaller font size on mobile
            fontFamily: "'Press Start 2P', cursive",
            margin: "0rem",
          }}>
            Success!
          </h2>
          <p style={{ textAlign: "center", width: isMobile ? "90%" : "30%", fontSize: isMobile ? "1rem" : "1.5rem" }}>
            If you have any questions, email us at
            <a style={{ color: "inherit", textDecoration: "none" }} href="mailto:help@pairplay.gg"> help@pairplay.gg</a>. 
          </p>
          <p style={{ textAlign: "center", width: isMobile ? "90%" : "30%", fontSize: isMobile ? "1.2rem" : "2rem" }}>
            GL HF, and get that W!
          </p>

          <Button
            variant="contained"
            color="primary"
            style={buttonStyles}
          >
            <Link style={{ color: "inherit", textDecoration: "none" }} to="/">Back to home</Link>
          </Button>
        </div>
      </section>
    );
  }

  return null;
};
