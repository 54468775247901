import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

export const BookingModal = ({ isOpen, onClose, onConfirm, notes, handleNotesChange, creatorName, handleSessionTypeChange, sessionType, handleGameChange, game, creatorGames, creatorOtherSessions, showGameDropdown, showOtherSessionsDropdown, showSessionTypeDropdown }) => {
  const maxLength = 500;
  const isTooManyChars = notes.length > maxLength;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>

        {showGameDropdown &&
          <FormControl required={showGameDropdown && !showOtherSessionsDropdown} style={{ marginBottom: "1rem" }} fullWidth margin="dense">
            <InputLabel id="game-label">Game</InputLabel>
            <Select
              labelId="game-label"
              id="game"
              label="Game"
              onChange={handleGameChange}
              value={game || ''}
            >
              {creatorGames.map((gameName) => (
                <MenuItem key={gameName} value={gameName}>
                  {gameName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }

        {showOtherSessionsDropdown &&
          <FormControl required={showOtherSessionsDropdown && !showGameDropdown} style={{ marginBottom: "1rem" }} fullWidth margin="dense">
            <InputLabel id="other-session-label">Other Sessions</InputLabel>
            <Select
              labelId="other-session-label"
              id="otherSession"
              label="Other Session Types"
              onChange={handleGameChange}
              value={game || ''}
              disabled={!showOtherSessionsDropdown}
            >
              {creatorOtherSessions.map((otherSessionName) => (
                <MenuItem key={otherSessionName} value={otherSessionName}>
                  {otherSessionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }

        {showSessionTypeDropdown &&
          <FormControl required={true} fullWidth margin="dense">
            <InputLabel id="session-type-label">Session Type</InputLabel>
            <Select
              labelId="session-type-label"
              id="sessionType"
              label="Session Type"
              onChange={handleSessionTypeChange}
              value={sessionType || ''}
            >
              <MenuItem value="Coaching">Coaching</MenuItem>
              <MenuItem value="Competitive">Competitive</MenuItem>
              <MenuItem value="For Fun">For Fun</MenuItem>
              <MenuItem value="1v1">1v1</MenuItem>
              <MenuItem value="Other">Other (specify below)</MenuItem>
            </Select>
          </FormControl>
        }

      </DialogContent>
      {game === "Other" ?
        <DialogTitle>Please add notes describing your request! Note: it is up to {creatorName} to accept your request.</DialogTitle>
        : game === "Dead by Daylight" ?
        <DialogTitle>Please add your Discord username below.</DialogTitle>
        :
        <DialogTitle>Add notes you'd like to pass along to {creatorName}! (optional)</DialogTitle>
      }
      <DialogContent>
        <TextField
          autoFocus
          required={game === "Other" ? true : false}
          margin="dense"
          id="notes"
          label="Notes"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          minRows={3}
          maxRows={6}
          value={notes}
          onChange={handleNotesChange}
          inputProps={{ maxLength }}
          helperText={`${notes.length}/${maxLength}`}
          FormHelperTextProps={{
            sx: { textAlign: 'left' }
          }}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          sx={{
            padding: '1rem',
            fontSize: '1.2rem',
            width: '50%',
            maxWidth: '300px',
          }}
          variant="contained"
          onClick={onConfirm}
          color="primary"
          disabled={isTooManyChars}
        >
          Confirm
        </Button>
      </DialogActions>

    </Dialog>
  );
};
