import * as React from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../AuthContext";
import { Link } from "react-router-dom";
import FourZeroFour from "../components/FourZeroFour";
import baseUrl from "../url";
import Loading from "../components/Loading";
import { Grid, Card, CardMedia, CardContent, Typography, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useInView } from 'react-intersection-observer';
import '../styles.css';
import { LandingUserModal } from "../components/LandingUserModal";

export const HomePage = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setAuthUser } = React.useContext(AuthContext);
  const [user, setUser] = React.useState(null);
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const queryUserId = queryParams.get('userId');
  const [creators, setCreators] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    const fetchUser = async () => {
      let fetchUserId = queryUserId || localStorage.getItem('userId');
      if (!fetchUserId) return;

      try {
        const res = await axios.get(`${baseUrl}/api/users/${fetchUserId}`);
        const fetchedUser = res.data;
        setAuthUser(fetchedUser);  
        setUser(fetchedUser);  
        if (token) {
          localStorage.setItem("jwt", token);  
          localStorage.setItem("userId", fetchedUser.id);  
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUser();
  }, [token, queryUserId, setAuthUser]);

  React.useEffect(() => {
    async function fetchCreators() {
      try {
        const creators = await axios.get(`${baseUrl}/api/users/creators`);
        const creatorsWithFollowers = creators.data.filter(creator => creator.followers != null);
        const creatorsWithoutFollowers = creators.data.filter(creator => creator.followers == null);

        creatorsWithFollowers.sort((a, b) => b.followers - a.followers);
        creatorsWithoutFollowers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        const sortedCreators = [...creatorsWithFollowers, ...creatorsWithoutFollowers];

        setTimeout(() => {
          setCreators(sortedCreators);
          setLoading(false);
        }, 500);
      } catch (e) {
        setError(e);
        console.log(e);
        setLoading(false);
      }
    }

    fetchCreators();
  }, []);

  if (loading || !creators) return <Loading />;

  if (creators.length === 0) return <p>nothing yet</p>;

  if (error) return <FourZeroFour />;

  return (
    <>
      <h1 style={{
        textAlign: "center",
        fontSize: isMobile ? "2rem" : "4rem", 
        fontFamily: "'Press Start 2P', cursive",
        margin: isMobile ? "0 0 2rem 0" : "",
      }}>
        PairPlay
      </h1>
      {!user ?
      <LandingUserModal isMobile={isMobile} />
      : null }
      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
        <TextField
          label="Search for creators"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: '30%',
            '@media (max-width:800px)': {
              width: '70%' 
            },
          }}
        />
      </div>
      <div className="homeCardDivMargin">
        <Grid container spacing={2}>
          {
          creators
          .filter(creator => creator.creator_name?.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((creator) => (
            <Grid item xs={6} sm={4} md={3} key={creator.id}>
              <Link to={`/${creator.creator_name.replace(/\s+/g, '-')}`} state={{ creator, user }} style={{ textDecoration: 'none', color: 'inherit' }}>
                <CreatorCard creator={creator} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
}

const CreatorCard = ({ creator }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '50px 0px',
  });

  return (
    <Card
      ref={ref}
      sx={{
        borderRadius: '6px',
        transition: '0.3s',
        opacity: inView ? 1 : 0,
        transform: inView ? 'none' : 'scale(0.95)',
        '&:hover': {
          boxShadow: `0 0 20px 0 rgba(0, 0, 0, 0.2), 
            0 12px 24px 0 rgba(0, 0, 0, 0.19), 
            0 0 10px 6px #FFF`,
        },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '550px',
        width: "auto",
      }}
    >
      <CardMedia
        component="img"
        alt={creator.creator_name || ""}
        sx={{
          width: '100%',
          height: { xs: '200px', sm: '300px', md: '350px' },
          objectFit: 'cover',
        }}
        image={creator.profile_picture}
        loading="lazy"
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          sx={{
            textAlign: "center",
            color: '#fff',
            fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
            '&:hover': {
              color: theme => theme.palette.primary.main,
              textShadow: '0 0 10px currentColor',
            }
          }}
          variant="h5"
          component="div"
        >
          {creator.creator_name || ""}
        </Typography>
      </CardContent>
    </Card>
  );
}