import React from 'react';
import {
  Routes,
  Route,
  // useLocation,
  // useNavigate
} from 'react-router-dom'
import { HomePage } from "./containers/HomePage";
import { CreatorPage } from "./components/CreatorPage/CreatorPage";
import Navbar from "./components/Navbar/Navbar";
import LoginPage from "./components/Login/Login";
import SignupPage from "./components/Login/Signup";
import Dashboard from "./components/Dashboard/Dashboard";
import { CheckoutForm } from "./components/Stripe/CheckoutForm";
import { Return } from "./components/Stripe/Return";
// import LandingPage from './components/LandingPage';
import axios from 'axios';
import baseUrl from './url';
import Footer from './components/Footer/Footer';
import FAQPage from './components/Footer/FAQs';
import Legal from './components/Footer/Legal';

function App() {
  axios.defaults.baseURL = `${baseUrl}`;
  axios.defaults.headers.common['apikey'] = process.env.REACT_APP_SUPABASE_KEY;

  // Use an interceptor to add the token to every request
  axios.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem('jwt');
      if (token) {
        config.headers.Authorization = `${token}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );


  return (
    <>
      <div className="App">
        <Navbar />
        <div style={{ flex: 1 }}>
          <Routes>
            {/* <Route path="*" element={<FourZeroFour />} /> */}
            {/* <Route exact path="/" element={<LandingPage />} /> */}
            <Route path="/" element={<HomePage />} />
            <Route path="/:creatorName" element={<CreatorPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/checkout" element={<CheckoutForm />} />
            <Route path="/return" element={<Return />} />
            <Route path="/faqs" element={<FAQPage />} />
            <Route path="/legal" element={<Legal />} />
          </Routes>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
