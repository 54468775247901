import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  Container,
  Grid,
  Box,
  Button,
} from '@mui/material';

import axios from 'axios';
import baseURL from '../../url';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AvailableSessions } from './AvailableSessions';
import { SessionDetails } from './SessionDetails';

import '../../styles.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CalendarButtons } from './CalendarButtons';
import { Calendar } from './Calendar';
import FourZeroFour from '../FourZeroFour';
import { BookingModal } from './BookingModal';
import { CreatorPageHeaderCard } from './CreatorPageHeaderCard';
import RequestTimeModal from './RequestTimeModal';

export const CreatorPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { creatorName } = useParams();

  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedDaySessions, setSelectedDaySessions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [creator, setCreator] = useState(null);
  const [user, setUser] = useState(null);

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userNotes, setUserNotes] = useState("");
  const [sessionType, setSessionType] = useState(null);
  const [game, setGame] = useState(null);
  const [availableSessions, setAvailableSessions] = useState([]);
  const availableSessionsRef = useRef(null);
  const handleNotesChange = (event) => setUserNotes(event.target.value);
  const handleSessionTypeChange = (event) => setSessionType(event.target.value);

  const [showGameDropdown, setShowGameDropdown] = useState(true);
  const [showOtherSessionsDropdown, setShowOtherSessionsDropdown] = useState(true);
  const [showSessionTypeDropdown, setShowSessionTypeDropdown] = useState(true);

  const [creatorGames, setCreatorGames] = useState([]);
  const [creatorOtherSessions, setCreatorOtherSessions] = useState([]);

  const [isRequestTimeModalOpen, setIsRequestTimeModalOpen] = useState(false);

  const handleGameChange = (event) => {
    setGame(event.target.value);

    if (creatorGames.includes(event.target.value)) {
      setShowOtherSessionsDropdown(false); // Hide other sessions dropdown
      setShowSessionTypeDropdown(true); // Show session type dropdown
      // Reset the value of other sessions if game is selected
      setSessionType('');
    } else {
      setShowGameDropdown(false); // Hide game dropdown
      setShowSessionTypeDropdown(false); // Hide session type dropdown
    }
  };

  // Calculate event rate
  const selectedEventRate = creator?.hourly_rate || 0;

  useEffect(() => {
    const fetchCreatorData = async () => {
      if (!location.state?.creator) {
        try {
          const response = await axios.get(`${baseURL}/api/users/creator/${creatorName}`);
          setCreator(response.data);
        } catch (error) {
          console.error('Error fetching creator data:', error);
          setError(error);
        }
      } else {
        setCreator(location.state.creator);
      }
    };

    const fetchUserData = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (userId) { // Ensure there is a userId to avoid unnecessary fetch attempts
          const response = await axios.get(`${baseURL}/api/users/${userId}`);
          setUser(response.data);
        } else {
          // Handle scenario when there's no userId in localStorage
          console.log("No userId found in localStorage.");
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    fetchUserData();
    fetchCreatorData();
  }, [creatorName]);

  useEffect(() => {
    if (creator) {
      setCreatorGames(creator.games || []);
      setCreatorOtherSessions(creator.other_session_types || []);
      setLoading(true);
      const fetchAvailableSessions = async () => {
        try {
          const response = await axios.get(`${baseURL}/api/users/creator-calendar/${creator.id}`);
          setAvailableSessions(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching available sessions:', error);
          setError(error);
        }
      };

      fetchAvailableSessions();
    }
  }, [creator]);

  // Set the selected day to today by default
  useEffect(() => {
    if (availableSessions.length > 0) {
      const updatedSelectedDaySessions = availableSessions.filter(session =>
        dayjs(session.start.dateTime).isSame(dayjs(selectedDate), 'day')
      );
      setSelectedDaySessions(updatedSelectedDaySessions);
    }
  }, [availableSessions, selectedDate]);

  const smoothScrollTo = (element) => {
    const targetPosition = element.getBoundingClientRect().top + document.documentElement.scrollTop;
    const startPosition = document.documentElement.scrollTop;
    const distance = targetPosition - startPosition;
    const duration = 1500; // Duration in milliseconds
    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      window.scrollTo(0, startPosition + distance * (progress / duration));
      if (progress < duration) window.requestAnimationFrame(step);
    };

    window.requestAnimationFrame(step);
  };

  const handleDayClick = ({ dateStr }) => {
    const selectedDaySessions = availableSessions.filter(session =>
      dayjs(session.start.dateTime).isSame(dayjs(dateStr), 'day')
    );
    setSelectedDaySessions(selectedDaySessions);
    setSelectedDate(dateStr); // Set the selected date

    setTimeout(() => {
      if (availableSessionsRef.current) {
        smoothScrollTo(availableSessionsRef.current);
      }
    }, 100);
  };

  const handleDeleteSessionClick = async () => {
    if (selectedEvents.length === 0) {
      toast.error("No session selected to delete.");
      return;
    }
  
    try {
      setLoading(true);
      const sessionIds = selectedEvents.map(event => event.id);
      await axios.post(`${baseURL}/api/users/creator-calendar/${creator.id}/delete`, { sessionIds });
      setSelectedEvents([]);
      toast.success("Session(s) deleted successfully.");
      // Refresh the calendar to reflect the deleted session
      const response = await axios.get(`${baseURL}/api/users/creator-calendar/${creator.id}`);
      setAvailableSessions(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to delete session:", error);
      setLoading(false);
      toast.error("Failed to delete session. Please try again.");
    }
  };
  

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);

    // Reset the form fields when the modal is closed
    if (!isModalOpen) {
      setUserNotes("");
      setSessionType(null);
      setGame(null);

      setShowGameDropdown(true);
      setShowOtherSessionsDropdown(true);
      setShowSessionTypeDropdown(true);
    }
  };

  const handleBookingError = () => {
    if (!user) {
      toast.error('You must log in to book a session.');
      return;
    }
  };

  const handleUnselectSessionClick = () => {
    setSelectedEvents([]);
  };

  const handleModalClose = () => {
    toggleModal();
    setUserNotes(""); // Clear notes on cancel
  };

  const handleModalConfirm = () => {
    if (!sessionType && (showGameDropdown && !showOtherSessionsDropdown)) {
      toast.error('Please select a session type.');
      return;
    }

    if (!game) {
      toast.error('Please select a game or other session option.');
      return;
    }

    if (game === "Call of Duty" && !user.activision_id) {
      toast.error('Please add an Activision ID to your profile in your dashboard.');
      return;
    }

    if (game === "Valorant" && !user.riot_id) {
      toast.error('Please add a Riot ID to your profile in your dashboard.');
      return;
    }

    if ((game === "Fortnite" || game === "Fall Guys") && !user.epic_username) {
      toast.error('Please add an Epic Username to your profile in your dashboard.');
      return;
    }

    if (game === "Minecraft" && !user.gamertag) {
      toast.error('Please add a Gamertag to your profile in your dashboard.');
      return;
    }

    if (game === "Apex Legends" && !user.ea_id) {
      toast.error('Please add an EA/Origin ID to your profile in your dashboard.');
      return;
    }

    if (game === "Dead by Daylight" && !user.dead_by_daylight_id) {
      toast.error('Please add a Dead by Daylight ID to your profile in your dashboard.');
      return;
    }

    if (game === "GTA V" && !user.rockstar_social_club_account) {
      toast.error('Please add a Rockstar Social Club account to your profile in your dashboard.');
      return;
    }

    if (game === "Other" && !userNotes) {
      toast.error('Please add notes for the creator describing your request.');
      return;
    }

    setLoading(true);
    navigate('/checkout', { state: { creator, selectedEvents, user, userNotes, sessionType, game } });
    toggleModal();
  };

  const handleRequestTime = () => {
    setIsRequestTimeModalOpen(true);
  };

  const renderRequestTimeButton = () => (
    <Box textAlign="center" mt={2} mb={2}>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleRequestTime}
        sx={{ mt: 2 }}
      >
        Request a Time
      </Button>
    </Box>
  );

  if (loading || !creator) return <Loading />;

  if (error) return <FourZeroFour />;

  return (
    <>
      <Container>
        <CreatorPageHeaderCard creator={creator} isMobile={isMobile} />
      </Container>
      <div style={{ width: "100%" }}>
        {!isMobile &&
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              {/* Empty Grid item to occupy the left quarter of the screen */}
            </Grid>
            <Grid className='calendar-container' item xs={12} md={6} style={{ paddingBottom: "5rem" }}>
              {/* Centered content (Calendar and Buttons) */}
              <Calendar handleDayClick={handleDayClick} availableSessions={availableSessions} isMobile={isMobile} />
              {selectedEvents.length > 0 &&
                <>
                  <SessionDetails selectedEvents={selectedEvents} selectedEventRate={selectedEventRate} isMobile={isMobile} />
                  <CalendarButtons user={user} creator={creator} selectedEvents={selectedEvents} handleBookingError={handleBookingError} handleUnselectSessionClick={handleUnselectSessionClick} isMobile={isMobile} smoothScrollTo={smoothScrollTo} toggleModal={toggleModal} handleDeleteSessionClick={handleDeleteSessionClick} />
                  <BookingModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    onConfirm={handleModalConfirm}
                    notes={userNotes}
                    handleNotesChange={handleNotesChange}
                    creatorName={creator.creator_name}
                    handleSessionTypeChange={handleSessionTypeChange}
                    sessionType={sessionType}
                    handleGameChange={handleGameChange}
                    game={game}
                    creatorGames={creatorGames}
                    creatorOtherSessions={creatorOtherSessions}
                    showGameDropdown={showGameDropdown}
                    showOtherSessionsDropdown={showOtherSessionsDropdown}
                    showSessionTypeDropdown={showSessionTypeDropdown}
                  />
                </>
              }
            </Grid>
            <Grid item xs={12} md={3}>
              <AvailableSessions
                sessions={selectedDaySessions}
                onSelect={(sessions) => {
                  setSelectedEvents(sessions);
                }}
                selectedDate={selectedDate}
                isMobile={isMobile}
                availableSessionsRef={availableSessionsRef}
                selectedEvents={selectedEvents}
              />
              {renderRequestTimeButton()}
            </Grid>
          </Grid>
        }
        {isMobile &&
          <div>
            <Calendar handleDayClick={handleDayClick} availableSessions={availableSessions} isMobile={isMobile} />
            <AvailableSessions
              sessions={selectedDaySessions}
              onSelect={(sessions) => {
                setSelectedEvents(sessions);
              }}
              selectedDate={selectedDate}
              isMobile={isMobile}
              availableSessionsRef={availableSessionsRef}
              selectedEvents={selectedEvents}
            />
            {renderRequestTimeButton()}
            {selectedEvents.length > 0 &&
              <>
                <SessionDetails selectedEvents={selectedEvents} selectedEventRate={selectedEventRate} isMobile={isMobile} />
                <CalendarButtons user={user} creator={creator} selectedEvents={selectedEvents} handleBookingError={handleBookingError} handleUnselectSessionClick={handleUnselectSessionClick} isMobile={isMobile} smoothScrollTo={smoothScrollTo} toggleModal={toggleModal} handleDeleteSessionClick={handleDeleteSessionClick} />
                <BookingModal
                  isOpen={isModalOpen}
                  onClose={handleModalClose}
                  onConfirm={handleModalConfirm}
                  notes={userNotes}
                  handleNotesChange={handleNotesChange}
                  creatorName={creator.creator_name}
                  handleSessionTypeChange={handleSessionTypeChange}
                  sessionType={sessionType}
                  handleGameChange={handleGameChange}
                  game={game}
                  creatorGames={creatorGames}
                  creatorOtherSessions={creatorOtherSessions}
                  showGameDropdown={showGameDropdown}
                  showOtherSessionsDropdown={showOtherSessionsDropdown}
                  showSessionTypeDropdown={showSessionTypeDropdown}
                />
              </>
            }
          </div>
        }
        <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
      </div>
      <RequestTimeModal
        open={isRequestTimeModalOpen}
        onClose={() => setIsRequestTimeModalOpen(false)}
        creator={creator}
        availableSessions={availableSessions}
        user={user}
      />
    </>
  );
};