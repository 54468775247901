import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
  Typography,
  Button,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import '../../styles.css';

export const AvailableSessions = ({ sessions, onSelect, selectedDate, isMobile, availableSessionsRef, selectedEvents }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const theme = useTheme();

  const handleSessionClick = (session) => {
    const isSelected = selectedEvents.some(event => event.id === session.id);
    if (isSelected) {
      onSelect(selectedEvents.filter(event => event.id !== session.id));
    } else {
      onSelect([...selectedEvents, session]);
    }
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography style={{
        width: "90%",
        textAlign: "center",
        margin: '18px 18px 18px 0px',
        color: (selectedDate && sessions.length > 0) ? undefined : theme.palette.secondary.main,
        fontSize: isMobile ? ".9rem" : "1.5rem",
      }} variant="h6">
        {selectedDate && sessions.length > 0 ? 'Available' : 'No Available'} Sessions on{' '}
        {dayjs(selectedDate).format('MMMM D, YYYY')}
      </Typography>

      <div style={{ display: "flex", flexDirection: "column", width: "85%", justifyContent: "space-around" }}>
        {sessions.map((session, index) => {
          const isSelected = selectedEvents.some(event => event.id === session.id);
          return (
            <Button
              ref={index === 0 ? availableSessionsRef : undefined }
              key={index}
              variant="outlined"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => handleSessionClick(session)}
              style={{
                margin: isMobile ? ".3rem" : '.5rem',
                padding: isMobile ? ".5rem 1rem" : '1rem 2.5rem',
                marginBottom: index === sessions.length - 1 ? '1.5rem' : undefined,
                fontSize: isMobile ? ".9rem" : '1.2rem',
                borderColor: isSelected ? theme.palette.primary.main : hoveredIndex === index ? theme.palette.primary.main : undefined,
                borderWidth: isSelected ? '2px' : undefined,
                color: isSelected ? "black" : undefined,
                backgroundColor: isSelected ? theme.palette.primary.light : undefined,
              }}
            >
              {dayjs(session.start.dateTime).format('h:mm A')} - {dayjs(session.end.dateTime).format('h:mm A')}
            </Button>
          );
        })}
      </div>
    </Box>
  );
};
