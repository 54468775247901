import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const LandingUserModal = ({ isMobile }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem('lastShownDate');
    const today = new Date().toISOString().split('T')[0];

    if (lastShown !== today) {
      setOpen(true);
      localStorage.setItem('lastShownDate', today);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="how-to-play-title"
      PaperProps={{
        sx: {
          width: '80%',
          maxHeight: '80vh',
          position: 'absolute',
          top: '10%',
        }
      }}
    >
      <DialogTitle
        id="how-to-play-title"
        sx={{ fontSize: isMobile ? '1.1rem' : '2rem' }}
        style={{ fontWeight: 'bold' }}
      >
        How to Play:
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ paddingTop: "0", paddingBottom: "0"}} dividers>
        <List sx={{ pl: 2, pt: 0, pb: 0, 'li': { listStyleType: 'disc', display: 'list-item' } }}>
          <ListItem style={{ padding: "0" }}>
            <ListItemText primary="Sign in with your Google Account" primaryTypographyProps={{ fontSize: isMobile ? '1rem' : '1.5rem' }} />
          </ListItem>
          <ListItem style={{ padding: "0" }}>
            <ListItemText primary="Add your gaming username/ID in your dashboard" primaryTypographyProps={{ fontSize: isMobile ? '1rem' : '1.5rem' }} />
          </ListItem>
          <ListItem style={{ padding: "0" }}>
            <ListItemText primary="Book a session!" primaryTypographyProps={{ fontSize: isMobile ? '1rem' : '1.5rem' }} />
          </ListItem>
          <ListItem style={{ padding: "0" }}>
            <ListItemText primary="Drop in with your favorite creators, create core memories, and get that W!" primaryTypographyProps={{ fontSize: isMobile ? '1rem' : '1.5rem' }} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{
            padding: '1rem',
            fontSize: '1.2rem',
            width: '50%',
            maxWidth: '300px',
          }}
        >
          Let's Go!
        </Button>
      </DialogActions>
    </Dialog>
  );
};
