import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import axios from 'axios';
import baseURL from '../../url';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@mui/material/styles';

import { supportedGames, otherSessionTypes } from '../../config';

export const CreatorModal = ({ isOpen, onClose, user }) => {
  const theme = useTheme();
  const [creatorName, setCreatorName] = useState('');
  const [channelUrl, setChannelUrl] = useState('');
  const [comments, setComments] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [selectedGames, setSelectedGames] = useState([]);

  const handleGameSelect = (game) => {
    if (selectedGames.includes(game)) {
      setSelectedGames(selectedGames.filter(g => g !== game));
    } else {
      setSelectedGames([...selectedGames, game]);
    }
  };

  const handleSubmit = async () => {
    if (user.is_creator) {
      toast.error('You are already a creator ya dingus')
      return;
    }

    if (selectedGames.length === 0) {
      toast.error('Please select at least one game')
      return;
    }

    if (!creatorName) {
      toast.error('Please add your creator name')
      return;
    }

    if (!channelUrl) {
      toast.error('Please add your channel URL')
      return;
    }

    const emailData = {
      creatorName,
      channelUrl,
      comments,
      selectedGames,
      referralCode,
    };

    try {
      // Replace with your API endpoint or server logic to send email
      await axios.post(`${baseURL}/api/sendgrid/creator-request`, {
        emailData,
        user,
      });
      toast.success('Request sent successfully!');
      onClose(); // Close the modal after sending the email
    } catch (error) {
      console.error('Error sending email', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Become a Creator</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="creatorName"
          label="Creator Name"
          type="text"
          fullWidth
          variant="outlined"
          value={creatorName}
          onChange={(e) => setCreatorName(e.target.value)}
          required={true}
        />
        <TextField
          margin="dense"
          id="channelUrl"
          label="Stream Channel URL"
          type="text"
          fullWidth
          variant="outlined"
          value={channelUrl}
          onChange={(e) => setChannelUrl(e.target.value)}
          required={true}
        />

        <div style={{ marginTop: '20px', textAlign: "Center" }}>
          <p>Select Games Available for Sessions *</p>
          <div style={{
            // display: 'flex', justifyContent: 'center', gap: '10px', margin: "1rem"
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', // Creates four columns
            justifyContent: 'center',
            gap: '10px',
            margin: "1rem 0rem"
          }}>
            {supportedGames.map((game) => (
              <Button
                key={game}
                variant={selectedGames.includes(game) ? "contained" : "outlined"}
                color="primary"
                onClick={() => handleGameSelect(game)}
                style={{
                  transition: 'all 0.3s ease',
                  boxShadow: selectedGames.includes(game) ? `0 0 10px ${theme.palette.primary.light}` : 'none',
                  textTransform: 'none',
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.boxShadow = `0 0 10px ${theme.palette.primary.light}`;
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseOut={(e) => {
                  if (!selectedGames.includes(game)) {
                    e.currentTarget.style.boxShadow = 'none';
                    e.currentTarget.style.transform = 'scale(1)';
                  }
                }}
              >
                {game}
              </Button>
            ))}
          </div>
        </div>

        <div style={{ marginTop: '20px', textAlign: "Center" }}>
          <p>Select Other Available Sessions</p>
          <div style={{
            // display: 'flex', justifyContent: 'center', gap: '10px', margin: "1rem"
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', // Creates four columns
            justifyContent: 'center',
            gap: '10px',
            margin: "1rem 0rem"
          }}>
            {otherSessionTypes.map((game) => (
              <Button
                key={game}
                variant={selectedGames.includes(game) ? "contained" : "outlined"}
                color="primary"
                onClick={() => handleGameSelect(game)}
                style={{
                  transition: 'all 0.3s ease',
                  boxShadow: selectedGames.includes(game) ? `0 0 10px ${theme.palette.primary.light}` : 'none',
                  textTransform: 'none',
                }}
                onMouseOver={(e) => {
                  e.currentTarget.style.boxShadow = `0 0 10px ${theme.palette.primary.light}`;
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseOut={(e) => {
                  if (!selectedGames.includes(game)) {
                    e.currentTarget.style.boxShadow = 'none';
                    e.currentTarget.style.transform = 'scale(1)';
                  }
                }}
              >
                {game}
              </Button>
            ))}
          </div>
        </div>


      <div>
        <p>Please note if you were referred by a creator, agency, or organization. Feel free to leave any other comments as well.</p>
        <TextField
          margin="dense"
          id="comments"
          label="Comments (optional)"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          minRows={3}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        </div>

        <div>
          <TextField
            margin="dense"
            id="referralCode"
            label="Referral Code (optional)"
            type="text"
            fullWidth
            variant="outlined"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
        </div>

      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button
          sx={{
            padding: '1rem',
            fontSize: '1.2rem',
            width: '50%',
            maxWidth: '300px',
          }}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
      <ToastContainer toastStyle={{ backgroundColor: "#333", color: "#fff" }} />
    </Dialog>
  );
};
